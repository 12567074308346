import * as toastr from 'toastr';

/**
 * @typedef ToastIconClasses
 * @type {object}
 * @property {?string} error - The error toast class (default: 'toast-error')
 * @property {?string} info - The info toast class (default: 'toast-info')
 * @property {?string} success - The success toast class (default: 'toast-success')
 * @property {?string} warning - The warning toast class (default: 'toast-warning')
 */

/**
 * @typedef ToastOptions
 * @type {object}
 * @property {boolean|undefined} tapToDismiss - Weather the toast should be dismissed when clicked (default: true)
 * @property {string|undefined} toastClass - The class used for toast messages (WARNING changing this might break styling) (default: 'toast')
 * @property {string|undefined} containerId - The class used for the main container of all toasts (WARNING changing this might break styling) (default: 'toast-container')
 * @property {boolean|undefined} debug - Weather to debug log the toast plugin (default: false)
 * @property {show'|'hide'|'fadeIn'|'fadeOut'|'slideDown'|'slideUp'|undefined} showMethod - Define the animation for initially showing a toast (default: 'fadeIn')
 * @property {number|undefined} showDuration - The time in ms it takes for the show animation to complete (default: 300ms)
 * @property {swing'|'linear'|undefined} showEasing - Animation show easing (default: 'swing')
 * @property {any} onShown - callback for when the toast is shown
 * @property {'show'|'hide'|'fadeIn'|'fadeOut'|'slideDown'|'slideUp'|undefined} hideMethod - Define the animation to show when hiding a toast (default: 'fadeOut')
 * @property {number|undefined} hideDuration - How long it takes for the hide animation to finish in ms (default: 1000ms)
 * @property {'swing'|'linear'|undefined} hideEasing - Animation hide easing (default: 'swing')
 * @property {any} onHidden - callback for when the toast is hidden
 * @property {ToastIconClasses|undefined} iconClasses,
 * @property {string|undefined} iconClass - Base toast icon class (default: 'toast-info'),
 * @property {'toast-top-left'|'toast-top-right'|'toast-top-centre'|'toast-top-full-width'|
 *            'toast-bottom-left'|'toast-bottom-right'|'toast-bottom-centre'|
 *            'toast-bottom-full-width'|undefined} positionClass - Position of toast on the screen (default: 'toast-top-right')
 * @property {number|undefined} extendedTimeOut - Time to extend the timeout by a specified number of ms when hovered (default: 1000ms)
 * @property {number|undefined} timeOut - Time taken to hide the toast (default: 5000) (Set timeOut and extendedTimeOut to 0 to make it sticky)
 * @property {string|undefined} titleClass - Class set on the title (WARNING changing this might break styling) (default: 'toast-title')
 * @property {string|undefined} messageClass -  Class set on the toast content (WARNING changing this might break styling) (default: 'toast-message')
 * @property {boolean|undefined} escapeHtml - Weather to escape html tags in toast content (default: false),
 * @property {boolean|undefined} closeButton - Weather the close button is visible (default: true)
 * @property {string|undefined} closeHtml - Set custom html to use for the close button (default: '<button type="button">&times;</button>'),
 * @property {string|undefined} closeClass - The class to append to the close button element (WARNING changing this might break styling) (default: 'toast-close-button'),
 * @property {boolean|undefined} newestOnTop - Weather the latest is at the top or bottom of the stack (default: true)
 * @property {boolean|undefined} preventDuplicates - Weather we prevent duplicate toast content (default: true)
 * @property {boolean|undefined} progressBar - Weather to display a progress bar based on the timeOut time (default: true)
 * @property {string|undefined} progressClass - The css class used for the progress bar of the toast (WARNING changing this might break styling) (default: 'toast-progress')
 * @property {boolean|undefined} rtl - Weather the toast should be shown right to left (default: false)
 */

/**
 * @type {ToastOptions}
 */
toastr.options = { progressBar: true, preventDuplicates: true, closeButton: true, positionClass: 'toast-bottom-right' };
function toastIconWrapper(message, status = 'info') {
    let toastIcon;
    switch (status) {
        case 'success':
            toastIcon = 'fa fa-circle-check';
            break;
        case 'error':
            toastIcon = 'fa fa-info-circle';
            break;
        case 'warning':
            toastIcon = 'fa fa-info-circle';
            break;
        default:
            toastIcon = 'fa fa-info-circle';
            break;
    }

    return `
        <div class="toast-wrapper">
            <i class="toast-icon ${toastIcon}"></i>
            ${message}
        </div>
    `;
}

export class Toast {

    /**
     * Display a success toast message
     * @param message {string} body content of the toast can include html
     * @param title {?string} toast main header
     * @param options {ToastOptions} overwrite the global defaults for this specific toast message
     */
    static success(message, title = null, options = {}) {
        toastr.success(toastIconWrapper(message, 'success'), title, options);
    }

    /**
     * Display an error toast message
     * @param message {string} the body content of the toast can include html
     * @param title {?string} toast main header
     * @param options {ToastOptions} overwrite the global defaults for this specific toast message
     */
    static error(message, title = null, options = {}) {
        toastr.error(toastIconWrapper(message, 'error'), title, options);
    }

    /**
     * Display a warning toast message
     * @param message {string} the body content of the toast can include html
     * @param title {?string} toast main header
     * @param options {ToastOptions} overwrite the global defaults for this specific toast message
     */
    static warning(message, title = null, options = {}) {
        toastr.warning(toastIconWrapper(message, 'warning'), title, options);
    }

    /**
     * Display an info toast message
     * @param message {string} the body content of the toast can include html
     * @param title {?string} toast main header
     * @param options {ToastOptions} overwrite the global defaults for this specific toast message
     */
    static info(message, title = null, options = {}) {
        toastr.info(toastIconWrapper(message), title, options);
    }

    /**
     * Remove all active toasts while remaining animated
     */
    static clearToasts() {
        toastr.clear();
    }

    /**
     * Immediately remove all visible toasts without animating
     */
    static removeToasts() {
        toastr.remove();
    }
}

$(() => app.TOAST = Toast);
