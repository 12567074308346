/* CUSTOMISED */
/* https://www.codexworld.com/multi-select-dropdown-list-with-checkbox-jquery */
/* CUSTOMISED */
!function (t) {
    var e = {
        placeholder: "Select options",
        columns: 1,
        search: !1,
        searchOptions: {
            default: "Filter", showOptGroups: !1, onSearch: function (t) {
            }
        },
        selectAll: !1,
        selectGroup: !1,
        minHeight: 200,
        maxHeight: null,
        showCheckbox: !0,
        jqActualOpts: {},
        onLoad: function (e) {
            t(e).hide()
        },
        onOptionClick: function (t, e) {
        },
        onOpen: function(e, $el){
        },
        maxWidth: null,
        minSelect: !1,
        maxSelect: !1
    }, i = 1;

    function o(i, o) {
        this.element = i;
        this.options = t.extend({}, e, o);
        this.load();
        window.addEventListener('ReloadMultiSelect', (e) => {
            if(e.detail.id === this.options.id) {
                this.reload();
            }
        });
        window.addEventListener('ClearMultiSelect', (e) => {
            if(e.detail.id === this.options.id) {
                this.clear();
                this.reload();
            }
        });
        this.element.addEventListener('Rebuild', (e) => {
            const that = this;
            t(this.element).parent().find(".ms-options-wrap").remove();
            t(this.element).show({
                duration: 0,
                always: function() {
                   t(this).css("display", "").removeClass("jqmsLoaded");
                   that.load();
                }
            });
        });
    }

    o.prototype = {
        load: function () {
            var that = this;
            if ("SELECT" != that.element.nodeName || t(that.element).hasClass("jqmsLoaded")) return !0;
            t(that.element).addClass("jqmsLoaded"), t(that.element).after('<div class="ms-options-wrap"><button class="disable-exclude">None Selected</button><div class="ms-options"><ul></ul></div></div>');
            var button = t(that.element).parent().find(".ms-options-wrap").find("> button:first-child"),
                o = t(that.element).parent().find(".ms-options-wrap").find("> .ms-options"), s = o.find("> ul"),
                n = !!t(that.element).find("optgroup").length, l = null;
            "number" == typeof that.options.width ? (o.parent().css("position", "relative"), l = that.options.width) : "string" == typeof that.options.width ? (t(that.options.width).css("position", "relative"), l = "100%") : o.parent().css("position", "relative");
            var a, c = t(window).height() - o.offset().top - 20;
            that.options.maxHeight && (c = (c = t(window).height() - o.offset().top - 20) < that.options.minHeight ? that.options.minHeight : that.maxHeight), c = c < that.options.minHeight ? that.options.minHeight : c, o.css({
                maxWidth: l,
                minHeight: that.options.minHeight,
                maxHeight: c,
                overflow: "auto"
            }).hide()

            o.bind("touchmove mousewheel DOMMouseScroll", (function (e) {
                var i;
                t(this).outerHeight() < t(this)[0].scrollHeight && (i = (i = e.originalEvent).wheelDelta || -i.detail,
                t(this).outerHeight() + t(this)[0].scrollTop > t(this)[0].scrollHeight && (e.preventDefault(), this.scrollTop += i < 0 ? 1 : -1))
            }))

            t(document).off("click.ms-hideopts").on("click.ms-hideopts", (function (e) {
                t(e.target).closest(".ms-options-wrap").length || t(".ms-options-wrap > .ms-options:visible").hide();
            }))

            button.bind("mousedown", (function (i) {

                that.options.onOpen(i, o);

                return 1 != i.which || (t(".ms-options-wrap > .ms-options:visible").each((function () {
                    t(this).parent().prev()[0] != o.parent().prev()[0] && t(this).hide()
                })), o.toggle(), void (o.is(":visible") && (o.css("maxHeight", ""), i = t(window).height() - o.offset().top - 20, that.options.maxHeight && (i = (i = t(window).height() - o.offset().top - 20) < that.options.minHeight ? that.options.minHeight : that.maxHeight), i = i < that.options.minHeight ? that.options.minHeight : i, i = app.OPTIONS.form ? 300 : i, o.css("maxHeight", i))))
            })).click((function (t) {
                t.preventDefault()
            }))

            that.options.placeholder && button.text(that.options.placeholder), that.options.search && (s.before('<div class="ms-search"><input type="text" value="" placeholder="' + that.options.searchOptions.default + '" /></div>'), (a = o.find(".ms-search input")).on("keyup", (function () {
                return t(this).data("lastsearch") == t(this).val() || (t(this).data("lastsearch", t(this).val()), "function" == typeof that.options.searchOptions.onSearch && that.options.searchOptions.onSearch(that.element), void s.find("li:not(.optgroup)").each((function () {
                    -1 < t(this).text().toLowerCase().indexOf(a.val().toLowerCase()) ? t(this).show() : t(this).hasClass("selected") || t(this).hide(), !that.options.searchOptions.showOptGroups && t(this).closest("li.optgroup") && (t(this).closest("li.optgroup").show(), t(this).closest("li.optgroup").find("li:visible").length ? t(this).closest("li.optgroup").show() : t(this).closest("li.optgroup").hide())
                })))
            })))

            that.options.selectAll && s.before('<a href="#" class="ms-selectall ms-selectall-toggle global">Select all</a> <a href="#" class="ms-unselectall ms-selectall-toggle global">Un-Select all</a>'), o.on("click", ".ms-unselectall", (function (t) {
                $uc = s.find("li:visible input:checked").trigger("click"), o.find(".ms-search input").val("").trigger("keyup")
            }))

            o.on("click", ".ms-selectall", (function (e) {
                if (e.preventDefault(), t(this).hasClass("global")) {
                    $uc = s.find("li:not(:visible) input:checked").trigger("click");
                    s.find("li:visible input:not(:checked)").trigger("click")
                } else t(this).hasClass("global") ? (s.find("li:not(.optgroup)").filter(":not(.selected)").length ? s.find("li:not(.optgroup)").filter(":not(.selected)").find('input[type="checkbox"]') : s.find('li:not(.optgroup).selected input[type="checkbox"]')).trigger("click") : t(this).closest("li").hasClass("optgroup") && ((e = t(this).closest("li.optgroup")).find("li:not(.selected)").length ? e.find('li:not(.selected) input[type="checkbox"]') : e.find('li.selected input[type="checkbox"]')).trigger("click")
            }));
            var p = [];
            t(that.element).children().each((function () {
                if ("OPTGROUP" === this.nodeName) {
                    let e = [];
                    let count = 0;
                    t(this).children("option").each((function () {
                        e[count++] = {
                            name: t(this).text(),
                            value: t(this).val(),
                            checked: t(this).prop("selected")
                        }
                    }));
                    p.push({label: t(this).attr("label"), options: e});
                } else {
                    if ("OPTION" !== this.nodeName) return !0;
                    p.push({name: t(this).text(), value: t(this).val(), checked: t(this).prop("selected")})
                }
            })), that.loadOptions(p), n ? (s.find("> li:not(.optgroup)").css({
                float: "left",
                width: 100 / that.options.columns + "%"
            }), s.find("li.optgroup").css({clear: "both"}).find("> ul").css({
                "column-count": that.options.columns,
                "column-gap": 0,
                "-webkit-column-count": that.options.columns,
                "-webkit-column-gap": 0,
                "-moz-column-count": that.options.columns,
                "-moz-column-gap": 0
            }), this._ieVersion() && this._ieVersion() < 10 && s.find("li.optgroup > ul > li").css({
                float: "left",
                width: 100 / that.options.columns + "%"
            })) : (s.css({
                "column-count": that.options.columns,
                "column-gap": 0,
                "-webkit-column-count": that.options.columns,
                "-webkit-column-gap": 0,
                "-moz-column-count": that.options.columns,
                "-moz-column-gap": 0
            }), this._ieVersion() && this._ieVersion() < 10 && s.find("> li").css({
                float: "left",
                width: 100 / that.options.columns + "%"
            })), o.on("click", 'input[type="checkbox"]', (function () {
                t(this).closest("li").toggleClass("selected"),
                    o.parent().prev().find('option[value="' + t(this).val() + '"]').prop("selected",
                        t(this).is(":checked")).closest("select").trigger("change"),
                "function" == typeof that.options.onOptionClick && that.options.onOptionClick(that),
                    that._updatePlaceholderText()
            })), "function" == typeof that.options.onLoad ? that.options.onLoad(that.element) : t(that.element).hide()
        }, loadOptions: function (e, i) {
            i = "boolean" != typeof i || i;
            var o, s = this, n = t(s.element).parent().find(".ms-options-wrap").find("> .ms-options > ul");
            for (o in i && n.find("> li").remove(), e) {
                var l = e[o], a = t("<li></li>");
                if (l.hasOwnProperty("options")) for (var c in a.addClass("optgroup"), a.append('<span class="label primary-important">' + l.label + "</span>"), a.find("> .label").css({clear: "both"}), s.options.selectGroup && a.append('<a href="#" class="ms-selectall">Select all</a>'), a.append("<ul></ul>"), l.options) {
                    var p = l.options[c], h = t("<li></li>").addClass("ms-reflow");
                    s._addOption(h, p), a.find("> ul").append(h)
                } else l.hasOwnProperty("value") && (a.addClass("ms-reflow"), s._addOption(a, l));
                n.append(a)
            }
            n.find('.ms-reflow input[type="checkbox"]').each((function (e) {
                var i;
                t(this).css("display").match(/block$/) && (i = t(this).outerWidth() || 15, t(this).closest("label").css("padding-left", 2 * parseInt(t(this).closest("label").css("padding-left")) + i), t(this).closest(".ms-reflow").removeClass("ms-reflow"))
            })), s._updatePlaceholderText()
        }, unload: function () {
            t(this.element).parent().find(".ms-options-wrap").remove(), t(this.element).show((function () {
                t(this).css("display", "").removeClass("jqmsLoaded")
            }))
        }, reload: function () {
            t(this.element).parent().find(".ms-options-wrap").remove(), t(this.element).removeClass("jqmsLoaded"), this.load()
        }, clear: function() {
            const parent = t(this.element).parent();
            parent.find("select[multiple] option:selected").prop("selected", false);
            const o = parent.find(".ms-options-wrap").find("> .ms-options");
            o.find("> ul").find("li input:checked").trigger("click");
            o.find(".ms-search input").val("").trigger("keyup");
        }, _updatePlaceholderText: function () {
            var e = this, i = t(e.element).parent().find(".ms-options-wrap").find("> button:first-child"),
                o = t(e.element).parent().find(".ms-options-wrap").find("> .ms-options"),
                s = o.parent().prev(), n = [];
            s.find("option:selected").each((function () {
                n.push(t(this).text())
            })), i.text(n.join(", ")), s = i.clone().css({
                display: "inline",
                width: "auto",
                visibility: "hidden"
            }).appendTo(
                o.parent()),
                o = void 0 !== t.fn.actual ?
                    s.actual("width", e.options.jqActualOpts) :
                        s.width(), (void 0 !== t.fn.actual ?
                            i.actual("width", e.options.jqActualOpts) :
                        i.width()) < o ? i.text(n.length + " selected") :
                n.length ? i.text(n.join(", ")) : i.text(e.options.placeholder),
                s.remove()
        }, _addOption: function (e, o) {
            e.text(o.name), e.prepend(t('<input type="checkbox" value="" title="" />').val(o.value).attr("title", o.name).attr("id", "ms-opt-" + i)), o.checked && (e.addClass("default"), e.addClass("selected"), e.find('input[type="checkbox"]').prop("checked", !0)), o = t("<label></label>").attr("for", "ms-opt-" + i), e.wrapInner(o), this.options.showCheckbox || e.find('input[id="ms-opt-' + i + '"]').hide(), i += 1
        }, _ieVersion: function () {
            var t = navigator.userAgent.toLowerCase();
            return -1 != t.indexOf("msie") && parseInt(t.split("msie")[1])
        }
    }, t.fn.multiselect = function (e) {
        var i, s = arguments;
        return void 0 === e || "object" == typeof e ? this.each((function () {
            t.data(this, "plugin_multiselect") || t.data(this, "plugin_multiselect", new o(this, e))
        })) : "string" == typeof e && "_" !== e[0] && "init" !== e ? (this.each((function () {
            var n = t.data(this, "plugin_multiselect");
            n instanceof o && "function" == typeof n[e] && (i = n[e].apply(n, Array.prototype.slice.call(s, 1))), "unload" === e && t.data(this, "plugin_multiselect", null)
        })), i) : void 0
    }
}(jQuery);

$(() => {
    function setupMultiExtra() {
        app.DOM.content.find('.multiselect-container .multiselect-label').each((k, el) => {
            el = $(el);
            el.parent().find('.clear-filter').remove();
            const field = el.attr('for').replace('[]', '');
            const fieldEl = $(`#${field}`);
            const value = fieldEl.val();
            if (!value || value.length === 0) {
                return;
            }
            el.parent().append(`<a class="clear-filter"><i class="fa fa-times f-14"></i></a>`);
            el.parent().find('.clear-filter').on('click', (e) => {
                e.preventDefault();
                fieldEl.val('').trigger('change');
                console.log(el.hasClass('dashboard'));
                if (el.hasClass('dashboard')) {
                    app.DASHBOARD_CORE.updateFilters();
                    window.location.reload();
                }

                if (app.URI[1] === 'list') {
                    window.location.href = app.DOM.filter_submit_clear.attr('href') ?? window.location.href + '/filter/clear';
                }

                fieldEl.multiselect('reload');
            });
        });
    }

    setupMultiExtra();
});